import type {FC} from 'react';
import {FaTwitter} from 'react-icons/fa';
import {MdKeyboardArrowRight, MdInfo} from 'react-icons/md';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';
import {
  searchCompaniesFromIndustryItems,
  searchReportsFromIndustryItems,
  footerMenuItems,
  footerMenuItems2,
} from './items';

type Props = {
  className?: string;
};

export const MobileFooter: FC<Props> = ({className}) => {
  const {user, isSignedIn} = useAuthAndCounts();

  return (
    <div className={className}>
      <div className="flex flex-col gap-48 bg-primary-1 px-16 py-24 text-white">
        <div className="flex flex-col gap-24">
          <div className="flex flex-col gap-12">
            <div className="flex items-center gap-8 font-bold">
              <MdInfo size={24} />
              外資就活ドットコムとは
            </div>
            <div className="text-sm text-white-1">
              外資就活ドットコムは、外資系・日系超一流企業を目指す学生のための就職活動サイトです。
              <br />
              トップ企業の求人情報から、過去の選考情報・先輩のエントリーシート・選考突破法・TOEIC勉強法・インターンシップまで多様なコンテンツを掲載中。
            </div>
            <div>
              <a
                href="https://twitter.com/gaishishukatsu"
                className="flex w-max items-center gap-4 rounded bg-sns-twitter px-8 py-4 text-xs"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size={14} />
                @gaishishukatsuさんをフォロー
              </a>
            </div>
          </div>
          {/* お問い合わせ用のAPIと繋ぎこむ必要性があるので、一旦コメントアウト */}
          {/* {isSignedIn && (
            <div className="flex flex-col gap-12">
              <div className="font-bold">御意見をお聞かせください</div>
              <div className="flex items-end gap-20">
                <textarea className="flex-1 rounded" />
                <button className="rounded bg-white px-24 py-8 text-sm text-primary-1">
                  送信する
                </button>
              </div>
              <div className="flex items-center gap-4 text-sm">
                <MdKeyboardArrowRight size={12} />
                返信の必要なお問い合わせは<a href="/inquiry">こちら</a>
              </div>
            </div>
          )} */}
        </div>
        <div className="flex flex-col gap-24">
          <div className="flex flex-col gap-12 text-white-1">
            <div className="font-bold">業界から企業を探す</div>
            <ul className="flex flex-col divide-y divide-overlay-2 text-sm">
              {searchCompaniesFromIndustryItems.map((item) => (
                <li key={item.label}>
                  <a
                    href={item.href}
                    className="flex items-center justify-between p-8"
                  >
                    {item.label}
                    <MdKeyboardArrowRight size={12} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col gap-12 text-white-1">
            <div className="text-sm font-bold">
              業界からエントリーシート（ES）・体験記を探す
            </div>
            <ul className="flex flex-col divide-y divide-overlay-2 text-sm">
              {searchReportsFromIndustryItems.map((item) => (
                <li key={item.label}>
                  <a
                    href={item.href}
                    className="flex items-center justify-between p-8"
                  >
                    {item.label}
                    <MdKeyboardArrowRight size={12} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-16 bg-primary px-16 py-8 text-white-1">
        <ul className="grid w-full grid-cols-2 text-sm font-bold">
          {footerMenuItems.map((item) => (
            <li key={item.label} className="border-b border-overlay-2">
              <a
                href={
                  // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                  item.href === '/recruiting_info' && user?.isEngineer
                    ? `${item.href}?label_ids=3`
                    : item.href
                }
                className="block p-8 text-center"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <ul className="flex flex-wrap justify-center gap-y-4 divide-x divide-white-1 text-sm">
          {footerMenuItems2.map((item) => {
            const {label, ...itemProps} = item;

            return (
              <li key={item.label} className="px-8">
                <a {...itemProps}>{label}</a>
              </li>
            );
          })}
        </ul>
        <div className="py-12 text-center text-sm text-primary-3">
          Copyright © 2010 Howtelevision,Inc.
        </div>
      </div>
    </div>
  );
};
