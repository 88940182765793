import type {FC} from 'react';
import {useRouter} from 'next/router';
import {MobileFooter} from './Footer/MobileFooter';
import {PcFooter} from './Footer/PcFooter';
import {EngineerMobileFooter} from './Footer/Engineer/EngineerMobileFooter';
import {EngineerPcFooter} from './Footer/Engineer/EngineerPcFooter';

type Props = {
  className?: string;
};

export const Footer: FC<Props> = ({className}) => {
  const router = useRouter();
  const isEngineerPage = router.pathname.includes('engineer');

  return (
    <div className={className}>
      <div className="pc:hidden">
        {isEngineerPage ? <EngineerMobileFooter /> : <MobileFooter />}
      </div>
      <div className="hidden pc:block">
        {isEngineerPage ? <EngineerPcFooter /> : <PcFooter />}
      </div>
    </div>
  );
};
