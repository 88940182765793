export const searchCompaniesFromIndustryItems = [
  {href: '/company?industries=1', label: 'コンサル・シンクタンクの企業'},
  {href: '/company?industries=2', label: '外資系金融・証券の企業'},
  {href: '/company?industries=3', label: '日系金融・証券の企業'},
  {href: '/company?industries=5', label: '商社の企業'},
  {href: '/company?industries=4', label: '外資系メーカー・サービスの企業'},
  {href: '/company?industries=8', label: '日系メーカー・サービスの企業'},
  {href: '/company?industries=6', label: '国家公務員・公的機関の企業'},
  {href: '/company?industries=7', label: 'ITサービスの企業'},
  {href: '/company?industries=11', label: 'デベロッパー・建設の企業'},
  {href: '/company?industries=9', label: 'マスコミ・広告の企業'},
];

export const searchReportsItems = [
  {href: '/company/reports?type=selection', label: '本選考'},
  {href: '/company/reports?type=intern', label: 'インターン'},
  {href: '/company/reports?type=entrysheet', label: 'エントリーシート'},
];

export const searchReportsFromIndustryItems = [
  {
    href: '/company/reports?category=1',
    label: 'コンサル・シンクタンクのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=2',
    label: '外資系金融・証券のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=3',
    label: '日系金融・証券のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=5',
    label: '商社のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=4',
    label: '外資系メーカー・サービスのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=8',
    label: '日系メーカー・サービスのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=6',
    label: '国家公務員・公的機関のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=7',
    label: 'ITサービスのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=11',
    label: 'デベロッパー・建設のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?category=9',
    label: 'マスコミ・広告のエントリーシート（ES）・体験記',
  },
];

export const searchReportsFromDepartmentItems = [
  {
    href: '/company/reports?department=1',
    label: 'コンサルタントのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=3',
    label: '投資銀行部門（IBD）のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=4',
    label: '銀行／証券マーケット業務のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=5',
    label: 'ミドル・バックオフィスのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=6',
    label: 'マーケティングのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=7',
    label: 'ファイナンスのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=8',
    label: 'セールス・営業のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=9',
    label: 'アクチュアリー・クオンツのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=10',
    label: 'システムエンジニアのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=13',
    label: 'R&D（技術研究職）のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=17',
    label: '総合職のエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=22',
    label: 'ジャーナリストのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=30',
    label: 'プログラマーのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=31',
    label: 'ソフトウェアエンジニアのエントリーシート（ES）・体験記',
  },
  {
    href: '/company/reports?department=32',
    label: 'データサイエンティストのエントリーシート（ES）・体験記',
  },
];

export const footerMenuItems = [
  {href: '/recruiting_info', label: '募集を探す'},
  {href: '/company', label: '企業を探す'},
  {href: '/company/reports', label: 'ES・体験記'},
  {href: '/column/categories', label: '就活コラム'},
  {href: '/questions', label: 'コミュニティ'},
  {href: '/box', label: '相談室'},
  {href: '/scouts', label: 'スカウト'},
  {href: '/schedule', label: 'スケジュール'},
  {href: '/recruiting_info?typeIDs=1&gy=2024', label: 'インターン'},
];

export const footerMenuItems2 = [
  {href: '/disclaimer', label: '著作権・免責事項'},
  {href: '/terms', label: '利用規約'},
  {href: '/service_privacy_policy', label: 'プライバシーポリシー'},
  {href: '/corporate_privacy_policy', label: '個人情報保護方針'},
  {
    href: 'http://howtelevision.co.jp/',
    label: '運営会社',
    target: '_blank',
    rel: 'noreferrer',
  },
  {href: '/faq', label: 'ヘルプ・お問い合わせ'},
  {
    href: 'https://biz.gaishishukatsu.com/contact/',
    label: '採用ご担当者の方はこちら',
  },
  {href: '/inquiry/tarekomi', label: '就活情報のタレコミ'},
  {href: 'https://liiga.me/', label: 'Liiga'},
  {href: 'https://mond.how/', label: 'Mond'},
];

export const engineerFooterMenuItems = [
  {href: '#recruiting_info', label: '募集を探す'},
  {href: '#companies', label: '企業を探す'},
  {href: '#company_reports', label: '選考対策'},
  {href: '#columns', label: 'コラム'},
  {href: '#events', label: 'イベント'},
];
