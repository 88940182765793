import {Image} from '@mantine/core';
import type {FC} from 'react';
import {FaTwitter} from 'react-icons/fa';
import {MdInfo} from 'react-icons/md';
import {ENGINEER_LOGO_IMAGE_PATH_PICTGRAM} from '../../../../config/constants/img';
import {engineerFooterMenuItems, footerMenuItems2} from '../items';

type Props = {
  className?: string;
};

export const EngineerPcFooter: FC<Props> = ({className}) => {
  return (
    <div className={className}>
      <div className="grid w-full grid-cols-12 gap-64 bg-black-3 p-40 text-white">
        <div className="col-span-5 flex flex-col gap-24">
          <div className="flex flex-col gap-12">
            <Image
              src={ENGINEER_LOGO_IMAGE_PATH_PICTGRAM}
              alt="logo"
              width={81}
              height={81}
            />
            <div className="flex items-center gap-8 font-bold mt-24">
              <MdInfo size={24} />
              外資就活ドットコムとは
            </div>
            <div className="text-sm text-white-1">
              外資就活ドットコムは、外資系・日系超一流企業を目指す学生のための就職活動サイトです。
              <br />
              エンジニア企業の求人情報、選考情報・先輩のエントリーシート・選考突破法、コーディング試験対策などの多様なイベント、エンジニア同士が集まる専用コミュニティなどを通して就活を支援します。
            </div>
            <div>
              <a
                href="https://twitter.com/gaishuengineer"
                className="flex w-max items-center gap-4 rounded bg-black-2 px-8 py-4 text-xs"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size={14} />
                @gaishuengineerさんをフォロー
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-16 bg-black-1 p-32 text-white-1">
        <ul className="flex w-max justify-center gap-32 border-b border-overlay-2 text-sm font-bold">
          {engineerFooterMenuItems.map((item) => (
            <li key={item.label}>
              <a href={item.href} className="block p-8">
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <ul className="flex divide-x divide-white-1 text-sm">
          {footerMenuItems2.map((item) => {
            const {label, ...itemProps} = item;

            return (
              <li key={item.label} className="px-8 text-white-2">
                <a {...itemProps}>{label}</a>
              </li>
            );
          })}
        </ul>
        <div className="text-sm text-black-5">
          Copyright © 2010 Howtelevision,Inc. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};
