import {BasicButton} from '@packages/ui';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useState, useEffect, type FC} from 'react';
import {FaSearch, FaTwitter} from 'react-icons/fa';
import {
  MdFacebook,
  MdAccountCircle,
  MdOpenInNew,
  MdMenu,
  MdClear,
} from 'react-icons/md';
import {routes} from '../../../config/constants';
import {
  LOGO_IMAGE_PATH_DEFAULT,
  LOGO_IMAGE_PATH_SQUARE,
} from '../../../config/constants/img';
import {type UserType} from '../../../core/usecases/useAuth';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';
import {useBooleanState} from '../../services/useBooleanState';
import {
  GSSKT_FACEBOOK_URL,
  GSSKT_TWITTER_URL,
} from '../../../config/constants/hosts';
import {useMediaQuery} from '../../services/useMediaQuery';
import {HeaderMenu} from './HeaderMenu';
import {HeaderReportMenu} from './HeaderReportMenu';
import {HeaderAuthActionMenu} from './HeaderAuthActionMenu';
import {PcHeaderMenu} from './PcHeaderMenu';

type Props = {
  scoutsCount?: number;
  isSignedIn?: boolean;
  interestsCount?: number;
  // OnLogout?: () => void
  className?: string;
};

const menuItems = [
  {
    name: '募集を探す',
    url: routes.RECRUITINGS,
  },
  {
    name: '企業を探す',
    url: routes.COMPANIES,
  },
  {
    name: 'ES・体験記',
    url: routes.REPORTS,
  },
  {
    name: '就活コラム',
    url: routes.COLUMNS,
  },
  {
    name: 'コミュニティ',
    url: routes.QUESTIONS,
  },
  {
    name: '相談室',
    url: routes.BOX_ANSWERS,
  },
  {
    name: 'AI選考対策',
    url: routes.REPORT_ANALYTICS_SEARCH,
    isNew: true,
  },
];

export const PcHeader: FC<Props> = ({className}) => {
  const {user, isIdle, scoutsCount, interestsCount, isSignedIn} =
    useAuthAndCounts();

  return (
    <div className={className}>
      {user && !isIdle ? (
        <>
          <AuthedHeader
            user={user}
            isSignedIn={isSignedIn}
            // OnLogout={onLogout}
            scoutsCount={scoutsCount}
            interestsCount={interestsCount}
          />
        </>
      ) : (
        <GuestHeader />
      )}
    </div>
  );
};

const AuthedHeader: FC<{user: UserType} & Props> = ({
  user,
  isSignedIn,
  // OnLogout,
  scoutsCount = 0,
  interestsCount = 0,
}) => {
  const [shownMenu, showMenu, hideMenu] = useBooleanState(false);
  const hasNotification = scoutsCount > 0 || interestsCount > 0;
  const router = useRouter();
  const {isLargeScreen} = useMediaQuery();
  const queryParam: string =
    typeof router.query.q === 'string' ? router.query.q : '';
  const [searchQuery, setSearchQuery] = useState(queryParam);
  const [opened, , close, toggle] = useBooleanState(false);

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    void router.push(`/search?q=${searchQuery}`);
  };

  const [shownReportMenu, showReportMenu, hideReportMenu] =
    useBooleanState(false);

  return (
    <>
      <div className="flex h-64 items-center justify-between bg-primary-1 px-64 text-base shadow-modal">
        {!isLargeScreen && (
          <>
            <div className="flex items-center">
              <button
                id="drawer-button"
                aria-label="Drawer Button"
                onClick={toggle}
                className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
              >
                {opened ? (
                  <MdClear size={24} className="text-white-1" />
                ) : (
                  <div className="relative">
                    <MdMenu fill="rgba(255, 255, 255, 0.8)" size={24} />
                    {hasNotification && (
                      <div className="absolute right-0 top-0 h-8 w-8 rounded-full bg-notification" />
                    )}
                  </div>
                )}
              </button>
            </div>
            <PcHeaderMenu
              items={menuItems}
              opened={opened}
              close={close}
              className="top-64"
            />
          </>
        )}
        <div className="flex items-center gap-20">
          <a href="/">
            <Image
              src={LOGO_IMAGE_PATH_SQUARE}
              alt="logo"
              width={40}
              height={40}
            />
          </a>
          {isLargeScreen && (
            <div className="flex gap-16 items-end">
              {menuItems.map((item) => (
                <div
                  key={item.name}
                  onMouseEnter={() => {
                    if (item.url !== routes.REPORTS) return;
                    showReportMenu();
                  }}
                  onMouseLeave={() => {
                    if (item.url !== routes.REPORTS) return;
                    hideReportMenu();
                  }}
                >
                  <div
                    className={`bg-accent-1 text-white font-bold rounded-12 text-center text-[8px] mx-auto w-1/2 ${item.url === routes.REPORT_ANALYTICS_SEARCH ? '' : 'hidden'}`}
                  >
                    NEW
                  </div>
                  <a
                    key={item.name}
                    href={
                      // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                      item.url === routes.RECRUITINGS && user.isEngineer
                        ? `${item.url}?tagIDs=3`
                        : // ES・体験記はドロップダウンのメニューを表示する
                          item.url === routes.REPORTS
                          ? '#'
                          : item.url
                    }
                    className="font-bold text-white-1"
                  >
                    {item.name}
                  </a>
                  {shownReportMenu && item.url === routes.REPORTS && (
                    <div className="absolute top-[42px] z-50 ml-[-12px] pt-[26px] ">
                      <div className="overflow-hidden rounded-12 bg-background-1 shadow-modal">
                        <HeaderReportMenu />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center gap-16">
          <div
            className="relative flex items-center gap-8 text-white-1"
            onMouseEnter={() => {
              showMenu();
            }}
            onMouseLeave={() => {
              hideMenu();
            }}
          >
            <div className="relative">
              <MdAccountCircle size={24} />
              {hasNotification && (
                <div className="absolute right-0 top-0 flex h-8 w-8 rounded-full bg-notification" />
                // TODO:bg-notification bg-primary-2のどちらかが正しいかは不明
                // <div className="absolute right-0 top-0 flex h-8 w-8 rounded-full bg-notification bg-primary-2" />
              )}
            </div>
            <div className="text-sm font-bold">{user.familyName}さん</div>
            {shownMenu && (
              <div className="absolute right-0 top-[22px] z-50 pt-[26px] ">
                <div className="overflow-hidden rounded-12 bg-background-1 shadow-modal">
                  <HeaderMenu
                    onItemClick={hideMenu}
                    scoutsCount={scoutsCount}
                    interestsCount={interestsCount}
                    showMyPage={isSignedIn}
                    // OnLogout={onLogout}
                    className="w-[184px] text-primary"
                  />
                </div>
              </div>
            )}
          </div>
          <form onSubmit={handleSearchSubmit} className="relative">
            <FaSearch
              size={24}
              className="absolute inset-y-8 left-16 text-primary-1"
            />
            <input
              className="w-[240px] rounded-full py-8 pl-48 pr-24"
              placeholder="企業や募集、コラムを検索"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </form>
        </div>
      </div>
      {user && user.isDemoUser && (
        <div className="bg-accent-2 p-4 w-full">
          <p className="text-base text-black text-center">
            現在プレビュー閲覧用のアカウントでログインしています。プレビュー閲覧以外を行いたい場合は個人のアカウントでログインし直してください。
          </p>
        </div>
      )}
    </>
  );
};

const GuestHeader: FC = () => {
  const router = useRouter();
  const queryParam: string =
    typeof router.query.q === 'string' ? router.query.q : '';
  const [searchQuery, setSearchQuery] = useState(queryParam);
  const [isFixedHeader, setIsFixedHeader] = useState<boolean>(false);

  const {isLargeScreen} = useMediaQuery();
  const [opened, , close, toggle] = useBooleanState(false);

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    void router.push(`/search?q=${searchQuery}`);
  };

  const [shownReportMenu, showReportMenu, hideReportMenu] =
    useBooleanState(false);
  const [shownAuthActionMenu, showAuthActionMenu, hideAuthActionMenu] =
    useBooleanState(false);

  const toggleVisibility = () => {
    setIsFixedHeader(window.scrollY >= 64);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className={`text-base shadow-modal${isFixedHeader ? ' mb-64' : ''}`}>
      <div className="flex h-64 items-center justify-between bg-primary-1 px-64">
        <a href="/">
          <div className="flex items-center gap-20">
            <Image
              src={LOGO_IMAGE_PATH_DEFAULT}
              alt="logo"
              width={84}
              height={19}
            />
            <div className="text-sm text-white-1">
              外資・日系トップ企業を目指す学生のための就職活動サイト「外資就活ドットコム」
            </div>
          </div>
        </a>
        <div className="flex items-center gap-8 text-white-1">
          <a
            href="https://go.gaishishukatsu.com/biz"
            target="_blank"
            rel="noopener noreferrer"
          >
            採用担当者様はこちら
          </a>
          <div className="ml-[-6px] mr-24 cursor-pointe">
            <MdOpenInNew />
          </div>
          <a
            href={GSSKT_FACEBOOK_URL}
            target="_blank"
            rel="noreferrer"
            aria-label="facebook"
          >
            <MdFacebook size={24} className="text-white" />
          </a>
          <a
            href={GSSKT_TWITTER_URL}
            target="_blank"
            rel="noreferrer"
            aria-label="twitter"
          >
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-white">
              <FaTwitter size={12} className="text-primary-1" />
            </div>
          </a>
        </div>
      </div>
      <div
        className={`${isFixedHeader ? 'fixed top-0 right-0 left-0 z-[100] ' : ''}flex h-64 items-center justify-between bg-primary px-64`}
      >
        <div className="relative flex items-center gap-20">
          {!isLargeScreen && (
            <>
              <div className="flex items-center">
                <button
                  id="drawer-button"
                  aria-label="Drawer Button"
                  onClick={toggle}
                  className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
                >
                  {opened ? (
                    <MdClear size={24} className="text-white-1" />
                  ) : (
                    <div className="relative">
                      <MdMenu fill="rgba(255, 255, 255, 0.8)" size={24} />
                    </div>
                  )}
                </button>
              </div>
              <PcHeaderMenu
                items={menuItems}
                opened={opened}
                close={close}
                className="top-64"
              />
            </>
          )}
          {isLargeScreen && (
            <>
              <div className="absolute flex items-center justify-start left-0 h-64 w-64">
                <a href="/">
                  <Image
                    src={LOGO_IMAGE_PATH_SQUARE}
                    alt="logo"
                    width={40}
                    height={40}
                  />
                </a>
              </div>
              <div
                className={`absolute h-64 flex gap-16 items-end pb-12 bg-primary duration-200 ease-out ${isFixedHeader ? 'left-64' : 'left-0'}`}
                style={{transitionProperty: 'left'}}
              >
                {menuItems.map((item) => (
                  <div
                    key={item.name}
                    className="flex flex-col items-center"
                    onMouseEnter={() => {
                      if (item.url !== routes.REPORTS) return;
                      showReportMenu();
                    }}
                    onMouseLeave={() => {
                      if (item.url !== routes.REPORTS) return;
                      hideReportMenu();
                    }}
                  >
                    <div
                      className={`bg-accent-1 text-white font-bold rounded-12 text-center text-[8px] mx-auto w-1/2 ${item.url === routes.REPORT_ANALYTICS_SEARCH ? '' : 'hidden'}`}
                    >
                      NEW
                    </div>
                    <a
                      key={item.name}
                      href={
                        // ES・体験記はドロップダウンのメニューを表示する
                        item.url === routes.REPORTS ? '#' : item.url
                      }
                      className="font-bold text-white-1 whitespace-nowrap"
                    >
                      {item.name}
                    </a>
                    {shownReportMenu && item.url === routes.REPORTS && (
                      <div className="absolute top-[42px] z-50 ml-[-12px] pt-[26px]">
                        <div className="overflow-hidden rounded-12 bg-background-1 shadow-modal">
                          <HeaderReportMenu />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="flex items-center gap-16">
          <div
            className="relative"
            onMouseEnter={showAuthActionMenu}
            onMouseLeave={hideAuthActionMenu}
          >
            <BasicButton isPrimary className="px-36">
              ログイン・新規登録
            </BasicButton>
            {shownAuthActionMenu && (
              <div className="absolute top-[40px] z-50 pt-[18px]">
                <div className="overflow-hidden rounded-12 bg-background-1 shadow-modal">
                  <HeaderAuthActionMenu />
                </div>
              </div>
            )}
          </div>
          <form onSubmit={handleSearchSubmit} className="relative">
            <FaSearch
              size={24}
              className="absolute inset-y-8 left-16 text-primary-1"
            />
            <input
              className="w-[240px] rounded-full py-8 pl-48 pr-24"
              placeholder="企業や募集、コラムを検索"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
