import type {AppError, Result} from '@packages/utils';
import {
  useMutation,
  type UseMutationResult,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type {z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {
  GetUserDataForAnalyticsResponse,
  GetUserMeInterestResponseSchema,
  GetUserMeScoutResponseSchema,
  GetUserProfileResponseSchema,
  UsersMeResponse,
  UsersMeFollowedCompaniesResponse,
  GetPopupMessageResponse,
  PutPopupMessageResponse,
  GetUsersMeEntryFormResponseSchema,
  GetUsersMeEntryFormsResponseSchema,
  DeleteEntryFormResponseSchema,
} from '../entities/usersMe/schema';
import {defaultClient} from '../../lib/defaultClient';
import {UnexpectedError} from '../../utils/unexpectedError';

type UseUserMeInterestUnreadCountArg = {
  config?: UseQueryOptions<GetUserMeInterestUnreadCountResponse>;
} & GetUserMeInterestUnreadCountArg;

type UseUserMeScoutUnreadCountArg = {
  config?: UseQueryOptions<GetUserMeScoutUnreadCountResponse>;
} & GetUserMeScoutUnreadCountArg;

type UseUserProfileInputRateArg = {
  config?: UseQueryOptions<GetUserProfileIputRateResponse>;
} & GetUserProfileIputRateArg;

type GetTagsArgs = {
  accessToken: string;
  version?: string;
};

export type GetUserDataForAnalyticsResponse = z.infer<
  typeof GetUserDataForAnalyticsResponse
>;

type UserDataReturnValueType = Result<
  GetUserDataForAnalyticsResponse,
  AppError
>;

export const getUserDataForAnalytics = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetTagsArgs): Promise<UserDataReturnValueType> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/users/me/analytics`,
    GetUserDataForAnalyticsResponse,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type GetUserMeInterestUnreadCountResponse = z.infer<
  typeof GetUserMeInterestResponseSchema
>;

export type GetUserMeInterestUnreadCountArg = {
  accessToken: string;
  version?: string;
};

export const getUserMeInterestUnreadCount = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetUserMeInterestUnreadCountArg) => {
  defaultClient.setToken(accessToken);
  const response = await defaultClient.get(
    `/${version}/users/me/interests/unread-count`,
    GetUserMeInterestResponseSchema,
  );

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response.value;
};

export type GetUserMeScoutUnreadCountResponse = z.infer<
  typeof GetUserMeScoutResponseSchema
>;

export type GetUserMeScoutUnreadCountArg = {
  accessToken: string;
  version?: string;
};

export const getUserMeScoutUnreadCount = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetUserMeScoutUnreadCountArg) => {
  defaultClient.setToken(accessToken);
  const response = await defaultClient.get(
    `/${version}/users/me/scout-messages/unread-count`,
    GetUserMeScoutResponseSchema,
  );

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response.value;
};

export type GetUserProfileIputRateResponse = z.infer<
  typeof GetUserProfileResponseSchema
>;

export type GetUserProfileIputRateArg = {
  accessToken: string;
  version?: string;
};

export const getUserProfileInputRate = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetUserProfileIputRateArg) => {
  defaultClient.setToken(accessToken);
  const response = await defaultClient.get(
    `/${version}/users/me/profile_input_rate`,
    GetUserProfileResponseSchema,
  );

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response.value;
};

export type UsersMeResponse = z.infer<typeof UsersMeResponse>;

type UsersMeReturnValueType = Result<UsersMeResponse, AppError>;

type GetUsersMeArgs = {
  accessToken: string;
  version?: string;
};

type UseUsersMeOptions = {
  config?: UseQueryOptions<UsersMeReturnValueType>;
} & GetUsersMeArgs;

export const getUsersMe = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetUsersMeArgs): Promise<UsersMeReturnValueType> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/users/me`,
    UsersMeResponse,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type UsersMeFollowedCompaniesResponse = z.infer<
  typeof UsersMeFollowedCompaniesResponse
>;

type UsersMeFollowedCompaniesResponseType = Result<
  UsersMeFollowedCompaniesResponse,
  AppError
>;

export const getUserFollowedCompanies = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetUsersMeArgs): Promise<UsersMeFollowedCompaniesResponseType> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/users/me/followed-companies`,
    UsersMeFollowedCompaniesResponse,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useUserMeInterestUnreadCount = ({
  config,
  ...args
}: UseUserMeInterestUnreadCountArg) => {
  const {data, isLoading} = useQuery<GetUserMeInterestUnreadCountResponse>(
    ['useUserMeInterestUnreadCount'],
    async () => getUserMeInterestUnreadCount(args),
    config,
  );

  const {count = 0} = data || {};

  return {
    count,
    isLoading,
  };
};

export const useUserMeScoutUnreadCount = ({
  config,
  ...args
}: UseUserMeScoutUnreadCountArg) => {
  const {data, isLoading} = useQuery<GetUserMeScoutUnreadCountResponse>(
    ['useUserMeScoutUnreadCount'],
    async () => getUserMeScoutUnreadCount(args),
    config,
  );

  const {count = 0} = data || {};

  return {
    count,
    isLoading,
  };
};

export const useUserProfileInputRate = ({
  config,
  ...args
}: UseUserProfileInputRateArg) => {
  const {data, isLoading} = useQuery<GetUserProfileIputRateResponse>(
    ['useUserProfileInputRate'],
    async () => getUserProfileInputRate(args),
    config,
  );

  return {
    profileInputRate: data,
    isLoading,
  };
};

export const useUsersMe = ({config, accessToken}: UseUsersMeOptions) => {
  defaultClient.setToken(accessToken);
  return useQuery<UsersMeReturnValueType>({
    ...config,
    queryKey: ['usersMe'],
    async queryFn() {
      return getUsersMe({accessToken});
    },
  });
};

// ポッアップメッセージ取得
export type GetPopupMessageResponse = z.infer<typeof GetPopupMessageResponse>;
export type GetPopupMessageReturnValueType = Result<GetPopupMessageResponse>;

export type GetPopupMessageArgs = {
  version?: string;
  accessToken: string;
};

type UseGetPopupMessageOptions = {
  config?: UseQueryOptions<GetPopupMessageReturnValueType>;
} & GetPopupMessageArgs;

export const getPopupMessage = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
}: GetPopupMessageArgs): Promise<GetPopupMessageReturnValueType> => {
  defaultClient.setToken(accessToken);
  const path = `/${version}/users/me/popup-message`;
  const result = await defaultClient.get(path, GetPopupMessageResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetPopupMessage = ({
  config,
  accessToken,
}: UseGetPopupMessageOptions) => {
  return useQuery<GetPopupMessageReturnValueType>({
    ...config,
    queryKey: ['GetPopupMessage'],
    async queryFn() {
      return getPopupMessage({accessToken});
    },
  });
};

export type PutPopupMessageResponse = z.infer<typeof PutPopupMessageResponse>;
export type PutPopupMessageReturnValueType = Result<PutPopupMessageResponse>;
export type PutPopupMessageDataType = string;
type PutPopupMessageType = {
  accessToken?: string;
  onSuccessFnc: () => void;
  onErrorFnc: (error: unknown) => void;
};

export type PutPopupMessageArgs = {
  version?: string;
  accessToken?: string;
  messageType: string;
};

export const putPopupMessage = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
  messageType,
}: PutPopupMessageArgs): Promise<PutPopupMessageReturnValueType> => {
  if (!accessToken) {
    throw new UnexpectedError('Unauthorized Error');
  }

  const path = `/${version}/users/me/close-popup`;
  const result = await defaultClient.put(path, PutPopupMessageResponse, {
    messageType,
  });
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const usePutPopupMessage = ({
  accessToken,
  onSuccessFnc,
  onErrorFnc,
}: PutPopupMessageType): UseMutationResult<
  PutPopupMessageReturnValueType,
  AppError,
  PutPopupMessageDataType
> => {
  return useMutation(
    async (messageType) => {
      return putPopupMessage({accessToken, messageType});
    },
    {
      onSuccess() {
        onSuccessFnc();
      },
      onError(error) {
        onErrorFnc(error);
      },
    },
  );
};

/**
 * GetUsersMeEntryForm
 */

export type GetUsersMeEntryFormResponse = z.infer<
  typeof GetUsersMeEntryFormResponseSchema
>;

export type GetUsersMeEntryFormReturnValueType = Result<
  GetUsersMeEntryFormResponse,
  AppError
>;

type GetUsersMeEntryFormArgs = {
  accessToken: string;
  version?: string;
  recruitmentId: string;
};

export const getUsersMeEntryForm = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  recruitmentId,
}: GetUsersMeEntryFormArgs): Promise<GetUsersMeEntryFormReturnValueType> => {
  if (!accessToken) {
    throw new UnexpectedError('Unauthorized Error');
  }

  defaultClient.setToken(accessToken);

  const path = `/${version}/users/me/entries/${recruitmentId}`;
  const result = await defaultClient.get(
    path,
    GetUsersMeEntryFormResponseSchema,
  );

  return result;
};

export const useUsersMeEntryForm = ({
  accessToken,
  recruitmentId,
}: GetUsersMeEntryFormArgs) => {
  return useQuery<GetUsersMeEntryFormReturnValueType>({
    queryKey: ['getUsersMeEntryForm', recruitmentId],
    async queryFn() {
      return getUsersMeEntryForm({accessToken, recruitmentId});
    },
  });
};

/**
 * GetUsersMeEntryForms
 */

export type GetUsersMeEntryFormsResponse = z.infer<
  typeof GetUsersMeEntryFormsResponseSchema
>;

export type GetUsersMeEntryFormsReturnValueType = Result<
  GetUsersMeEntryFormsResponse,
  AppError
>;

type GetUsersMeEntryFormsArgs = {
  accessToken: string;
  version?: string;
};

export const getUsersMeEntryForms = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetUsersMeEntryFormsArgs): Promise<GetUsersMeEntryFormsReturnValueType> => {
  if (!accessToken) {
    throw new UnexpectedError('Unauthorized Error');
  }

  defaultClient.setToken(accessToken);

  const path = `/${version}/users/me/entries`;
  const result = await defaultClient.get(
    path,
    GetUsersMeEntryFormsResponseSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetUsersMeEntryForms = ({
  accessToken,
  version,
}: GetUsersMeEntryFormsArgs) => {
  return useQuery<GetUsersMeEntryFormsReturnValueType>({
    queryKey: ['getUsersMeEntryForms'],
    async queryFn() {
      return getUsersMeEntryForms({accessToken});
    },
  });
};

/**
 * DeleteUsersMeEntryForm
 */

export type DeleteEntryFormRespons = z.infer<
  typeof DeleteEntryFormResponseSchema
>;

export type DeleteEntryFormReturnValueType = Result<
  DeleteEntryFormRespons,
  AppError
>;

type DeleteEntryFormArgs = {
  accessToken: string;
  version?: string;
  recruitmentId: string;
};

type DeleteEntryFormOptions = {
  accessToken: string;
  recruitmentId: string;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
};

export const deleteEntryForm = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  recruitmentId,
}: DeleteEntryFormArgs): Promise<DeleteEntryFormReturnValueType> => {
  if (!accessToken) {
    throw new UnexpectedError('Unauthorized Error');
  }

  defaultClient.setToken(accessToken);

  const path = `/${version}/users/me/entries/${recruitmentId}`;
  const result = await defaultClient.delete(
    path,
    DeleteEntryFormResponseSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useDeleteEntryForm = ({
  accessToken,
  recruitmentId,
  onSuccessFnc,
  onErrorFnc,
}: DeleteEntryFormOptions) => {
  return useMutation<DeleteEntryFormReturnValueType, AppError>(
    async () => {
      return deleteEntryForm({accessToken, recruitmentId});
    },
    {
      onSuccess() {
        onSuccessFnc();
      },
      onError(error) {
        onErrorFnc(error);
      },
    },
  );
};
