import {createElement} from 'react';

export const convertNlToBr = (text: string) => {
  const regex = /(\n)/g;
  return text.split(regex).map((line, i) => {
    if (regex.test(line)) {
      return createElement('br', {key: i});
    }

    return line;
  });
};

export const convertNlToBrForDangerouslySetInnerHTML = (text: string) => {
  const regex = /(<p>)([\s\S]*?)(?=<\/p>)/g;
  // Pタグのコンテンツ内で改行を<br>タグに置き換える
  return text.replaceAll(regex, (match, p1, p2) => {
    return ` ${p1}${p2.replaceAll('\r\n', '<br>').replaceAll('\n', '<br>')}`; // eslint-disable-line @typescript-eslint/no-unsafe-call
  });
};
