import {Drawer} from '@mantine/core';
import {MdOutlineFiberNew} from 'react-icons/md';
import {overlayConfig} from '../modalConfig';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';

type Props = {
  className?: string;
  items: Array<{
    name: string;
    url: string;
    isNew?: boolean;
  }>;
  opened: boolean;
  close: () => void;
};

export const PcHeaderMenu: React.FC<Props> = ({
  className,
  items,
  opened,
  close,
}) => {
  const {user} = useAuthAndCounts();
  return (
    <Drawer
      position="left"
      withCloseButton={false}
      opened={opened}
      onClose={() => {
        close();
      }}
      padding={0}
      size="40%"
      className="top-52"
      {...overlayConfig}
    >
      <div className="h-screen bg-background-2 p-16">
        <ul className="mt-12 flex flex-col divide-y divide-line">
          {items.map((item) => {
            return (
              <li key={item.name} className="p-12">
                <a
                  href={
                    // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                    item.url === '/recruiting_info' && user?.isEngineer
                      ? `${item.url}?label_ids=3`
                      : item.url
                  }
                  className="flex items-center font-bold leading text-primary-1"
                >
                  {item.isNew && <MdOutlineFiberNew size={36} />}
                  <span className="ml-8">{item.name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </Drawer>
  );
};
