import {
  useUserMeInterestUnreadCount,
  useUserMeScoutUnreadCount,
} from '../repositories/usersMeRepository';
import {type UserType, useAuth} from './useAuth';

type AuthAndCounts = {
  accessToken: string;
  user: UserType | undefined;
  isIdle: boolean;
  scoutsCount: number;
  interestsCount: number;
  signOut: () => void;
  isSignedIn: boolean;
};

export const useAuthAndCounts = (): AuthAndCounts => {
  // MEMO: アクセストークンなどの認証情報を取得
  const {user, accessToken, signOut, isIdle} = useAuth();

  const scoutsCount = accessToken
    ? useUserMeScoutUnreadCount({accessToken}).count
    : 0;
  const interestsCount = accessToken
    ? useUserMeInterestUnreadCount({accessToken}).count
    : 0;

  return {
    accessToken,
    user,
    isIdle,
    scoutsCount,
    interestsCount,
    signOut,
    isSignedIn: !isIdle && Boolean(user),
  };
};
