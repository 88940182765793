import {z} from 'zod';
import {GENDER_TYPE_LIST} from '../user/constants';
import {DEGREE_TYPE_LIST} from '../university/constants';

export const GetUserDataForAnalyticsResponse = z.object({
  id: z.number(),
  sex: z.number(),
  university: z.number(),
  major: z.number(),
  subject: z.number(),
  bun_ri: z.number(),
  grade: z.number(),
  graduation_year: z.number(),
  graduation_month: z.number(),
  current_univ_type: z.string(),
  user_registered: z.string(),
  university_txt: z.string(),
  major_txt: z.string(),
  subject_txt: z.string(),
  calculated_graduation_year: z.number(),
  sex_txt: z.string(),
  bun_ri_txt: z.string(),
  grade_txt: z.string(),
  current_univ_type_txt: z.string(),
});

export const GetUserMeScoutResponseSchema = z.object({
  count: z.number(),
});

export const GetUserMeInterestResponseSchema = z.object({
  count: z.number(),
});

export const GetUserProfileResponseSchema = z.object({
  profile_input_rate: z.number(),
  unfilled_fields: z.array(
    z.object({
      profile_edit_page_link: z.string(),
      unfilled_field_label: z.string(),
      required_action_label: z.string(),
    }),
  ),
});

export const ZodEnumDegreeTypeSchema = z.enum(DEGREE_TYPE_LIST);
export const UsersMeResponse = z.object({
  id: z.number(),
  isRegularUser: z.boolean(),
  isDemoUser: z.boolean(),
  isAgreedRule: z.boolean(),
  graduationYear: z.number(),
  rawGraduationYear: z.number(),
  rawGraduationMonth: z.number(),
  gradeChangeMonth: z.number(),
  communityNickname: z.string(),
  adviserUserName: z.string(),
  firstName: z.string(),
  familyName: z.string(),
  firstNameKana: z.string(),
  familyNameKana: z.string(),
  email: z.string(),
  birthday: z.string(),
  gender: z.enum(GENDER_TYPE_LIST),
  grade: z.object({
    id: z.number(),
    name: z.string(),
  }),
  degreeType: ZodEnumDegreeTypeSchema,
  universityName: z.string(),
  majorName: z.string(),
  subjectName: z.string(),
  nextCheckDate: z.string(),
  checkYear: z.number(),
  isEmailConfirmed: z.boolean(),
  pendingEmail: z.string(),
  isEngineer: z.boolean(),
});

const BackgroundImageURLs = z.object({
  '300x126': z.string(),
  '640x268': z.string(),
  '948x392': z.string(),
});

const LogoURLs = z.object({
  '140x105': z.string(),
  '200x150': z.string(),
  '400x300': z.string(),
});

export const CompaniesSchema = z.object({
  backgroundImageURLs: BackgroundImageURLs,
  capitalDescription: z.string(),
  catchPhrase: z.string(),
  employeeCount: z.number(),
  followerCount: z.number(),
  foundedYear: z.number(),
  id: z.number(),
  industry: z.string(),
  industryCategoryID: z.number(),
  isFollowed: z.boolean(),
  isNew: z.boolean(),
  isWishedIndustry: z.boolean(),
  isWishedJobType: z.boolean(),
  logoURLs: LogoURLs,
  name: z.string(),
  pageType: z.string(),
  studyUrl: z.string(),
});

export const UsersMeFollowedCompaniesResponse = z.object({
  companies: z.array(CompaniesSchema),
  nextPage: z.number(),
  totalCount: z.number(),
});

// ポップアップメッセージ
const PopupMessageSchema = z.object({
  messageType: z.string(),
});

export const GetPopupMessageResponse = PopupMessageSchema;

export const PutPopupMessageResponse = z.object({
  messageType: z.string(),
});

export const FollowAdCompanyListSchema = z.object({
  companies: z.array(CompaniesSchema),
});

// エントリーフォーム情報取得
const EntryFormScheduleSchema = z.object({
  id: z.number(),
  name: z.string(),
  venue: z.string(),
  startTime: z.string(),
  endTime: z.string(),
});

const EntryFormQuestionAndAnswersSchema = z.object({
  question: z.string(),
  answerType: z.string(),
  answer: z.string(),
  selectedOptions: z.array(z.string()),
  order: z.number(),
});

export const GetUsersMeEntryFormResponseSchema = z.object({
  id: z.number(),
  title: z.string(),
  companyName: z.string(),
  industryID: z.number(),
  type: z.string(),
  canCancel: z.boolean(),
  schedule: EntryFormScheduleSchema,
  questionsAndAnswers: z.array(EntryFormQuestionAndAnswersSchema),
  isSetFileUpload: z.boolean(),
});

// 自分が応募したエントリーフォーム一覧
export const GetUsersMeEntryFormsResponseSchema = z.array(
  z.object({
    id: z.number(),
    title: z.string(),
    companyName: z.string(),
    type: z.string(),
    isSetFileUpload: z.boolean(),
  }),
);

export const DeleteEntryFormResponseSchema = z.string().nullable();
