type Hosts = Record<string, string>;

export const LIIGA_HOST: Hosts = {
  production: 'https://liiga.me',
  staging: 'https://dev.liiga.me',
  sirloin: 'https://dev.liiga.me',
  tongue: 'https://dev.liiga.me',
  development: 'https://dev.liiga.me',
};

export const GSSKT_TWITTER_URL = 'https://twitter.com/gaishishukatsu';

export const GSSKT_FACEBOOK_URL = 'https://www.facebook.com/gaishishukatsu';
