import type {AppError, Result} from '@packages/utils';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type * as z from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {type MutationConfig} from '../../lib/react-query';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  ScoutSort,
  type MessageTypesEnum,
  type ScoutSortEnum,
  type ScoutStatusEnum,
  type UserTypesEnum,
} from '../entities/scouts/constants';
import {
  GetChatMessagesResponse,
  GetScoutResponse,
  GetScoutsResponse,
  GetUnnotifiedResponse,
  GetUnreadScoutMessagesCountResponse,
  PostChatMessagesResponse,
  PutNotifiedLogsResponse,
  ScoutMessageStatusResponse,
  type ChatMessage,
  type ChatMessageList,
  type PutNotifiedLogsData,
  type Scout,
  type ScoutList,
} from '../entities/scouts/schema';

// GetScout
export type ScoutStatusType = z.infer<typeof ScoutStatusEnum>;

export type ScoutType = z.infer<typeof Scout>;

export type GetScoutResponseType = z.infer<typeof GetScoutResponse>;

type ReturnScoutValueType = Result<GetScoutResponseType, AppError>;

type GetScoutOptions = {
  scoutID: number;
  accessToken: string;
  version?: string;
};

// GetScouts
export type ScoutListType = z.infer<typeof ScoutList>;

export type ScoutSortType = z.infer<typeof ScoutSortEnum>;

export type GetScoutsResponseType = z.infer<typeof GetScoutsResponse>;

type ReturnScoutsValueType = Result<GetScoutsResponseType, AppError>;

type GetScoutsArgs = {
  accessToken: string;
  status?: ScoutStatusType[];
  sort?: ScoutSortType;
  version?: string;
};

type UseGetScoutsOptions = {
  config?: UseQueryOptions<ReturnScoutsValueType>;
} & GetScoutsArgs;

// GetChatMessage

export type UserTypesType = z.infer<typeof UserTypesEnum>;

export type MessageTypesType = z.infer<typeof MessageTypesEnum>;

export type ChatMessageType = z.infer<typeof ChatMessage>;
export type ChatMessageListType = z.infer<typeof ChatMessageList>;

export type ChatMessagesType = z.infer<typeof GetChatMessagesResponse>;

type ReturnChatMessageValueType = Result<ChatMessagesType, AppError>;

type GetChatMessagesArgs = {
  accessToken: string;
  scoutID: number;
  page?: number;
  limit?: number;
  status?: ScoutStatusType;
  version?: string;
};

export type PostChatMessagesResponseType = z.infer<
  typeof PostChatMessagesResponse
>;

type ReturnPostChatMessagesValueType = Result<
  PostChatMessagesResponseType,
  AppError
>;

type PostChatMessageArgs = {
  accessToken: string;
  scoutID: number;
  messageType: MessageTypesType;
  body: string;
  version?: string;
};

// PutScoutMessage
export type ScoutMessageStatusResponse = z.infer<
  typeof ScoutMessageStatusResponse
>;

type ReturnPutScoutMessageValueType = Result<
  ScoutMessageStatusResponse,
  AppError
>;

type PutScoutMessageStatusArgs = {
  accessToken: string;
  scoutID: number;
  status: ScoutStatusType;
  version?: string;
};

type UsePutScoutMessageStatusOptions = {
  config?: MutationConfig<typeof putScoutMessageStatus>;
} & PutScoutMessageStatusArgs;

export type GetUnreadScoutMessagesCountResponse = z.infer<
  typeof GetUnreadScoutMessagesCountResponse
>;

export type GetUnreadScoutMessagesCountArg = {
  accessToken: string;
  version?: string;
};

// GetScout
export const getScout = async ({
  scoutID,
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetScoutOptions): Promise<ReturnScoutValueType> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/users/me/scout-messages/${scoutID}`,
    GetScoutResponse,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetScout = ({
  accessToken,
  scoutID,
  version = DEFAULT_API_VERSION,
}: GetScoutOptions) => {
  defaultClient.setToken(accessToken);
  const {data, isLoading} = useQuery<ReturnScoutValueType>(
    ['useGetScout', scoutID],
    {
      async queryFn() {
        return defaultClient.get(
          `/${version}/users/me/scout-messages/${scoutID}`,
          GetScoutResponse,
        );
      },
      enabled: scoutID !== 0,
    },
  );
  return {
    data,
    isLoading,
  };
};

// GetScouts
export const getScouts = async ({
  accessToken,
  status,
  sort = ScoutSort.new,
  version = DEFAULT_API_VERSION,
}: GetScoutsArgs): Promise<ReturnScoutsValueType> => {
  defaultClient.setToken(accessToken);
  let parameters: {status?: ScoutStatusType[]; sort?: ScoutSortType} = {
    sort,
  };
  if (status !== undefined) {
    parameters = {...parameters, status};
  }

  const path = createPathWithQuery(
    `/${version}/users/me/scout-messages`,
    parameters,
  );
  const result = await defaultClient.get(path, GetScoutsResponse);
  return result;
};

export const useInfiniteScouts = ({
  accessToken,
  status,
  sort = ScoutSort.new,
  version = DEFAULT_API_VERSION,
}: UseGetScoutsOptions): UseInfiniteQueryResult<
  ReturnScoutsValueType,
  AppError
> => {
  return useInfiniteQuery(
    ['scouts', status, sort],
    async ({pageParam: pageParameter = 1}) => {
      defaultClient.setToken(accessToken);
      let parameters: {status?: ScoutStatusType[]; sort?: ScoutSortType} = {
        sort,
      };
      if (status) {
        parameters = {...parameters, status};
      }

      const path = createPathWithQuery(`/${version}/users/me/scout-messages`, {
        page: pageParameter,
        ...parameters,
      });
      const result = await defaultClient.get(path, GetScoutsResponse);
      if (!result?.ok) {
        throw new Error(result.error);
      }

      return result;
    },
    {
      getPreviousPageParam: () => 1,
      getNextPageParam: (lastPage) => lastPage.value.nextPage || undefined,
    },
  );
};

// GetChatMessages
export const getChatMessages = async ({
  accessToken,
  scoutID,
  page = 1,
  limit = 20,
  version = DEFAULT_API_VERSION,
}: GetChatMessagesArgs): Promise<ReturnChatMessageValueType> => {
  defaultClient.setToken(accessToken);
  const path = createPathWithQuery(
    `/${version}/chat-rooms/scouts/${scoutID}/messages`,
    {
      page,
      limit,
    },
  );
  const result = await defaultClient.get(path, GetChatMessagesResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

// PostChatMessage
export const postChatMessage = async ({
  accessToken,
  scoutID,
  messageType,
  body,
  version = DEFAULT_API_VERSION,
}: PostChatMessageArgs): Promise<ReturnPostChatMessagesValueType> => {
  defaultClient.setToken(accessToken);
  const path = createPathWithQuery(
    `/${version}/chat-rooms/scouts/${scoutID}/messages`,
    {},
  );
  const result = await defaultClient.post(path, PostChatMessagesResponse, {
    messageType,
    body,
  });
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

// PutScoutMessage
export const putScoutMessageStatus = async ({
  accessToken,
  scoutID,
  status,
  version = DEFAULT_API_VERSION,
}: PutScoutMessageStatusArgs): Promise<ReturnPutScoutMessageValueType> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.put(
    `/${version}/users/me/scout-messages/${scoutID}/status`,
    ScoutMessageStatusResponse,
    {status},
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const usePutScoutMessageStatus = ({
  accessToken,
  scoutID,
  status,
  version = DEFAULT_API_VERSION,
}: UsePutScoutMessageStatusOptions) => {
  return useMutation<ReturnPutScoutMessageValueType>(async () => {
    return putScoutMessageStatus({accessToken, scoutID, status, version});
  });
};

// GetUnreadScoutMessage
export const getUnreadScoutMessagesCount = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetUnreadScoutMessagesCountArg) => {
  defaultClient.setToken(accessToken);
  const response = await defaultClient.get(
    `/${version}/chat-rooms/scouts/unread-messages`,
    GetUnreadScoutMessagesCountResponse,
  );

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response.value;
};

// GetUnnotifieds
type GetUnnotifiedArgs = {
  version?: string;
  accessToken: string;
};

export type GetUnnotifiedSchema = z.infer<typeof GetUnnotifiedResponse>;
type ResultGetUnnotifiedSchema = Result<GetUnnotifiedSchema, AppError>;

type UseGetUnnotifiedOptions = {
  config?: UseQueryOptions<ResultGetUnnotifiedSchema>;
} & GetUnnotifiedArgs;

export const useGetUnnotified = ({
  config,
  version,
  accessToken,
}: UseGetUnnotifiedOptions) => {
  return useQuery<ResultGetUnnotifiedSchema>({
    ...config,
    queryKey: ['useGetUnnotified'],
    async queryFn() {
      return getUnnotified({
        version,
        accessToken,
      });
    },
  });
};

export const getUnnotified = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
}: {
  accessToken: string;
  version?: string;
}) => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/users/me/scout-messages/unnotified`,
    GetUnnotifiedResponse,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

// PutNotifiedLogs
type putNotifiedLogsType = {
  accessToken: string;
  onSuccessFnc: () => void;
  onErrorFnc: (error: unknown) => void;
};

type ReturnPutValueType = Result<PutNotifiedLogsResponse, AppError>;

export type PutNotifiedLogsDataType =
  | z.infer<typeof PutNotifiedLogsData>
  | unknown;

export type PutNotifiedLogsResponse = z.infer<typeof PutNotifiedLogsResponse>;
export type ResultPutNotifiedLogsResponse = Result<
  PutNotifiedLogsResponse,
  AppError
>;

type PutNotifiedLogsArgs = {
  accessToken: string;
  data: PutNotifiedLogsDataType;
  version?: string;
};

export const usePutNotifiedLogs = ({
  accessToken,
  onSuccessFnc,
  onErrorFnc,
}: putNotifiedLogsType): UseMutationResult<ReturnPutValueType, AppError> => {
  return useMutation(
    async (data) => {
      return putNotifiedLogs({accessToken, data});
    },
    {
      onSuccess() {
        onSuccessFnc();
      },
      onError(error) {
        onErrorFnc(error);
      },
    },
  );
};

export const putNotifiedLogs = async ({
  accessToken,
  data,
  version = DEFAULT_API_VERSION,
}: PutNotifiedLogsArgs): Promise<ResultPutNotifiedLogsResponse> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.put(
    `/${version}/users/me/scout-messages/notified-logs`,
    PutNotifiedLogsResponse,
    JSON.stringify(data),
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};
