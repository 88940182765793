import classNames from 'classnames';
import {type FC, type MouseEvent, useCallback} from 'react';

type Props = {
  showMyPage?: boolean;
  scoutsCount?: number;
  interestsCount?: number;
  onItemClick?: () => void;
  // OnLogout?: () => void
  className?: string;
};

// Type MenuItem = {
//   id?: string
//   href?: string
//   label: string
// }

const items = [
  {id: 'login', href: '/login', label: 'ログイン'},
  {id: 'mypage', href: '/', label: 'マイページ'},
  {id: 'scouts', href: '/scouts', label: 'スカウト'},
  {id: 'interests', href: '/myInterests', label: '興味あり'},
  {href: '/schedule', label: 'スケジュール'},
  {href: '/mypage/profile', label: 'プロフィールを編集'},
  {href: '/mypage/reports', label: '選考体験記の管理'},
  {href: '/mypage/reports/add', label: '選考体験記の投稿'},
  {href: '/mypage/entrysheets', label: 'エントリシートの管理'},
  {href: '/mypage/es/new', label: 'エントリシートの投稿'},
  // { href: "/mypage/setting", label: "各種設定・その他" },
  {id: 'logout', href: '/logout', label: 'ログアウト'},
];

export const HeaderMenu: FC<Props> = ({
  showMyPage,
  scoutsCount,
  interestsCount,
  onItemClick,
  // OnLogout,
  className,
}) => {
  const onClickFactory = useCallback(
    // (item: MenuItem) => (e: MouseEvent) => {
    () => (e: MouseEvent) => {
      e.stopPropagation();
      // Const { id } = item
      // if (id === "logout") {
      //   // onLogout?.()
      // }
      onItemClick?.();
    },
    // [onItemClick, onLogout],
    [onItemClick],
  );

  return (
    <ul
      className={classNames(
        'bg-background-1 divide-line flex flex-col divide-y py-4 px-12 w-[184px]',
        className,
      )}
    >
      {items.map((item) => {
        const {id, href, label} = item;
        let notificationCount = 0;

        if ((!showMyPage && id !== 'login') || (showMyPage && id === 'login'))
          return;

        switch (id) {
          case 'mypage': {
            if (!showMyPage) return null;
            break;
          }

          case 'scouts': {
            notificationCount = scoutsCount ?? 0;
            break;
          }

          case 'interests': {
            notificationCount = interestsCount ?? 0;
            break;
          }

          default: {
            notificationCount = 0;
          }
        }

        return (
          <li key={id || item.href}>
            <a
              href={href}
              onClick={onClickFactory()}
              className="flex justify-between p-8"
            >
              <div>{label}</div>
              {notificationCount > 0 && (
                <div className="min-w-[28px] rounded-full bg-notification-2 p-4 text-center text-sm font-bold text-white">
                  {notificationCount >= 100 ? '99+' : notificationCount}
                </div>
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
