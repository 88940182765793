import {Drawer} from '@mantine/core';
import classNames from 'classnames';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {FaSearch} from 'react-icons/fa';
import {useEffect, useState} from 'react';
import {
  MdMenu,
  MdSearch,
  MdClear,
  MdAccountCircle,
  MdOpenInNew,
  MdOutlineFiberNew,
} from 'react-icons/md';
import {overlayConfig} from '../modalConfig';
import {routes} from '../../../config/constants';
import {LOGO_IMAGE_PATH_DEFAULT} from '../../../config/constants/img';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';
import {useBooleanState} from '../../services/useBooleanState';
import {HeaderMenu} from './HeaderMenu';

type Props = {
  scoutsCount?: number;
  interestsCount?: number;
  // OnLogout?: () => void
  className?: string;
};

const headerList: Array<{
  name: string;
  url: string;
  isNew?: boolean;
}> = [
  {
    name: 'AI選考対策',
    url: '/report_analytics/search',
    isNew: true,
  },
  {
    name: 'Software Engineer 就活',
    url: '/engineer',
  },
  {
    name: '募集を探す',
    url: '/recruiting_info',
  },
  {
    name: '企業を探す',
    url: '/company',
  },
  {
    name: 'ES・体験記',
    url: '/company/reports',
  },
  {
    name: '就活コラム',
    url: '/column/categories',
  },
  {
    name: 'コミュニティ',
    url: '/questions',
  },
  {
    name: '相談室',
    url: '/box/answers',
  },
  {
    name: '各種設定・その他',
    url: '/settings',
  },
];

export const MobileHeader: React.FC<Props> = ({className}) => {
  const {user, scoutsCount, interestsCount, isSignedIn, isIdle} =
    useAuthAndCounts();
  const [opened, , close, toggle] = useBooleanState(false);
  const [showSignInButton, setShowSignInButton] = useState(false);
  const router = useRouter();
  const queryParam: string =
    typeof router.query.q === 'string' ? router.query.q : '';
  const [searchQuery, setSearchQuery] = useState(queryParam);

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    void router.push(`/search?q=${searchQuery}`);
  };

  useEffect(() => {
    if (!isSignedIn && !isIdle) setShowSignInButton(true);
  }, [isSignedIn, isIdle]);

  const hasNotification = scoutsCount > 0 || interestsCount > 0;

  return (
    <div>
      <div
        className={classNames(
          'bg-primary-1 flex py-[14px] px-16 items-center w-full fixed top-0 z-[100]',
          className,
        )}
      >
        <div className="flex items-center">
          <button
            id="drawer-button"
            aria-label="Drawer Button"
            onClick={toggle}
            className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
          >
            {opened ? (
              <MdClear size={24} className="text-white-1" />
            ) : (
              <div className="relative">
                <MdMenu fill="rgba(255, 255, 255, 0.8)" size={24} />
                {hasNotification && (
                  <div className="absolute right-0 top-0 h-8 w-8 rounded-full bg-notification" />
                )}
              </div>
            )}
          </button>
        </div>
        {showSignInButton && (
          <div className="absolute right-0 top-0 flex">
            <a
              href={routes.LOGIN}
              className="flex h-52 items-center bg-primary-2 text-sm font-bold text-white-1"
            >
              <span className="mx-12">ログイン</span>
            </a>
            <a
              href={routes.MAIL_AUTH}
              className="flex h-52 items-center bg-accent-1 text-sm font-bold text-white-1"
            >
              <span className="mx-12">無料会員登録</span>
            </a>
          </div>
        )}
        <a
          href={'/'}
          className="ml-16 flex items-center pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
        >
          <Image
            src={LOGO_IMAGE_PATH_DEFAULT}
            width="84"
            height="19"
            alt="外資就活ドットコム"
          />
        </a>
        <div className="flex items-center">
          {/* // TODO 全体検索は後から実装 */}
          <div className="hidden">
            <MdSearch fill="rgba(255, 255, 255, 0.8)" size={20} />
          </div>
        </div>
        <Drawer
          position="left"
          withCloseButton={false}
          opened={opened}
          onClose={() => {
            close();
          }}
          padding={0}
          size="70%"
          className="top-52"
          {...overlayConfig}
        >
          <div className="h-full bg-background-2 p-16">
            <div className="overflow-hidden rounded-12 bg-white-1 p-12">
              {user && (
                <div className="flex gap-8 px-12 pb-4 pt-12">
                  <MdAccountCircle size={24} className="text-primary-1" />
                  <div className="font-bold text-primary-1">
                    {user.familyName}さん
                  </div>
                </div>
              )}
              <HeaderMenu
                showMyPage={isSignedIn}
                scoutsCount={scoutsCount}
                interestsCount={interestsCount}
                // OnLogout={onLogout}
                className="w-full bg-white-1 text-primary"
              />
            </div>
            <ul className="mt-12 flex flex-col divide-y divide-line">
              {headerList.map((item) => {
                return (
                  <li key={item.name} className="p-12">
                    <a
                      href={
                        // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                        item.url === '/recruiting_info' && user?.isEngineer
                          ? `${item.url}?label_ids=3`
                          : item.url
                      }
                      className="flex items-center font-bold leading text-primary-1"
                    >
                      {item.isNew && <MdOutlineFiberNew size={36} />}
                      <span className="ml-8">{item.name}</span>
                    </a>
                  </li>
                );
              })}
              {showSignInButton && (
                <li className="p-12">
                  <a
                    href="https://go.gaishishukatsu.com/biz"
                    className="flex items-center font-bold leading text-primary-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="ml-8">採用担当者様はこちら</span>
                    <span>
                      <MdOpenInNew />
                    </span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </Drawer>
      </div>
      <div className="bg-background-2 mt-[52px]">
        <form onSubmit={handleSearchSubmit} className="relative">
          <FaSearch
            size={24}
            className="absolute inset-y-8 left-16 top-12 text-black-3"
          />
          <input
            className="m-8 w-[95vw] rounded-4 py-8 pl-48"
            placeholder="キーワード検索"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        </form>
      </div>
      {user && user.isDemoUser && (
        <div className="bg-accent-2 p-8 w-full">
          <p className="text-sm text-black text-center">
            現在プレビュー閲覧用のアカウントでログインしています。
            <br />
            プレビュー閲覧以外を行いたい場合は個人のアカウントでログインし直してください。
          </p>
        </div>
      )}
    </div>
  );
};
