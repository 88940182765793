import {Button, Modal} from '@mantine/core';
import {differenceInDays} from 'date-fns';
import {useRouter} from 'next/router';
import {useEffect, useState, type FC} from 'react';
import {MdClose, MdEmail} from 'react-icons/md';
import {type GetUnnotifiedType} from '../../core/entities/scouts/schema';
import {
  useGetUnnotified,
  usePutNotifiedLogs,
} from '../../core/repositories/scoutRepository';
import {useAuth} from '../../core/usecases/useAuth';
import {useMediaQuery} from '../services/useMediaQuery';

type Props = Record<string, unknown>;

export const ScoutNotificationModal: FC<Props> = (props) => {
  const {user, isSignedIn, accessToken} = useAuth();
  const {isPC} = useMediaQuery();
  const router = useRouter();

  const [open, setOpen] = useState(false);
  const [unnotifieds, setUnnotifieds] = useState<GetUnnotifiedType>();
  const {data: unnotified} = useGetUnnotified({accessToken});
  useEffect(() => {
    if (unnotified?.ok && unnotified.value.unnotifieds.length > 0) {
      setOpen(true);
      setUnnotifieds(unnotified.value);
    }
  }, [unnotified?.ok]);

  const putNotifiedLogs = usePutNotifiedLogs({
    accessToken,
    onSuccessFnc() {
      console.log('success');
    },
    onErrorFnc(error: unknown) {
      console.log('error', error);
    },
  });

  const pushScouts = () => {
    putNotifiedLogs.mutate({
      scout_ids: unnotifieds?.unnotifieds.map(
        (unnotified) => unnotified.scoutID,
      ),
      ignored: 0,
    });
    setOpen(false);
    location.href = '/scouts';
  };

  const ignoreNotice = () => {
    putNotifiedLogs.mutate({
      scout_ids: unnotifieds?.unnotifieds.map(
        (unnotified) => unnotified.scoutID,
      ),
      ignored: 1,
    });
    setOpen(false);
  };

  return (
    <Modal
      opened={isSignedIn && router.pathname !== '/scouts' && open}
      onClose={() => {
        setOpen(false);
      }}
      title={
        <div className="flex items-center">
          <MdEmail size="32px" color={'#ffffff'} />
          <div className="pl-8 text-lg font-bold">Premiumスカウト</div>
        </div>
      }
      size="lg"
      padding="md"
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        // Modal's header background color
        '& .mantine-Modal-header': {
          backgroundColor: '#1E6A93',
        },
        // Modal's title color
        '& .mantine-Modal-title': {
          color: '#ffffff',
        },
      }}
      withCloseButton={false}
    >
      <div className="text-center py-24">
        <p className="text-black-2">
          {user?.familyName}さんに、企業からのスカウトメッセージが
          <span className="text-lg text-black-1 font-bold px-[4px]">
            {unnotifieds?.unnotifieds.length}通
          </span>
          届いています！
          <br />
          承諾期限まで、あと
          <span className="text-[#D9534F] text-lg font-bold px-[4px]">
            {differenceInDays(
              new Date(unnotifieds?.unnotifieds[0]?.deadline || new Date()),
              new Date(),
            )}
            日
          </span>
          です。
        </p>
      </div>

      <div className={`flex justify-around ${isPC && 'px-16'} items-center`}>
        {isPC ? (
          <PcButtons ignoreNotice={ignoreNotice} pushScouts={pushScouts} />
        ) : (
          <MobileButtons ignoreNotice={ignoreNotice} pushScouts={pushScouts} />
        )}
      </div>
    </Modal>
  );
};

type ButtonProps = {
  ignoreNotice: () => void;
  pushScouts: () => void;
};
const PcButtons: FC<ButtonProps> = ({ignoreNotice, pushScouts}) => {
  return (
    <>
      <Button
        variant="filled"
        className="bg-black-3/55 text-black-2/65 font-light"
        color="gray"
        onClick={() => {
          ignoreNotice();
        }}
      >
        <MdClose size="16px" />
        あとで
      </Button>
      <Button
        variant="filled"
        color="teal"
        className="mx-12 bg-emerald-600/80"
        size="lg"
        onClick={() => {
          pushScouts();
        }}
        fullWidth
      >
        スカウトメッセージを確認する
      </Button>
    </>
  );
};

const MobileButtons: FC<ButtonProps> = ({ignoreNotice, pushScouts}) => {
  return (
    <div className="flex-col flex gap-y-8 items-center">
      <Button
        variant="filled"
        className="bg-black-3/55 text-black-2/65 font-light w-[120px]"
        color="gray"
        size="xs"
        onClick={() => {
          ignoreNotice();
        }}
      >
        <MdClose size="16px" />
        あとで
      </Button>
      <Button
        variant="filled"
        color="teal"
        className="mx-4 bg-emerald-600/80"
        onClick={() => {
          pushScouts();
        }}
      >
        スカウトメッセージを確認する
      </Button>
    </div>
  );
};
