import {Box} from '@mantine/core';
import {MobilePromo} from '../../../features/promoPopup/components/MobilePromo';
import {GmailPromo} from '../../../features/promoPopup/components/GmailPromo';
import {ProfilePromo} from '../../../features/promoPopup/components/ProfilePromo';
import {ScoutPromo} from '../../../features/promoPopup/components/ScoutPromo';
import {OffereePromo} from '../../../features/promoPopup/components/OffereePromo';
import {useHandlePromoPopup} from '../../services/useHandlePromoPopup';

export const Popup: React.FC = () => {
  const {data, user, isOpen, isLoading, closePopup, isShow} =
    useHandlePromoPopup();

  let content;
  if (data?.ok) {
    switch (data.value?.messageType) {
      case 'mobile_promo': {
        content = (
          <MobilePromo
            closePopup={() => {
              closePopup(data.value?.messageType);
            }}
          />
        );
        break;
      }

      case 'gmail_promo': {
        content = (
          <GmailPromo
            closePopup={() => {
              closePopup(data.value?.messageType);
            }}
          />
        );
        break;
      }

      case 'profile_prompt': {
        content = (
          <ProfilePromo
            closePopup={() => {
              closePopup(data.value?.messageType);
            }}
          />
        );
        break;
      }

      case 'scout_on_promo': {
        content = user && (
          <ScoutPromo
            closePopup={() => {
              closePopup(data.value?.messageType);
            }}
            userName={user.familyName}
          />
        );

        break;
      }

      case 'offeree_survey_promo': {
        content = (
          <OffereePromo
            closePopup={() => {
              closePopup(data.value?.messageType);
            }}
          />
        );
        break;
      }

      default: {
        content = <></>;
      }
    }
  }

  return (
    <>
      {/* isOpenはポップアップを閉じているかを管理するためのフラグ
      isShowはPC画面や特定のURLでのみポップアップを表示するためのフラグ */}
      {data?.ok &&
        data.value.messageType !== '' &&
        isOpen &&
        isShow &&
        user && (
          <Box className="pc:bg-background-1 pc:pt-20 border-y border-primary-1 pc:border-0">
            <Box className="pc:bg-background-4 bg-background-1 mx-auto my-0 pc:max-w-[1200px] p-[10px] relative overflow-hidden items-center">
              {content}
            </Box>
          </Box>
        )}
    </>
  );
};
