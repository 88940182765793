import {useState} from 'react';
import {useRouter} from 'next/router';
import {
  useGetPopupMessage,
  usePutPopupMessage,
} from '../../core/repositories/usersMeRepository';
import {useAuth} from '../../core/usecases/useAuth';
import {HOME} from '../../config/constants/routes';
import {useMediaQuery} from './useMediaQuery';

export const useHandlePromoPopup = () => {
  const {user, accessToken} = useAuth();
  const {data, isLoading} = useGetPopupMessage({accessToken});
  const [isOpen, setIsOpen] = useState(true);
  const savePopup = usePutPopupMessage({
    accessToken,
    onSuccessFnc() {
      setIsOpen(false);
    },
    onErrorFnc(err: unknown) {
      setIsOpen(false);
    },
  });
  const closePopup = (messageType: string) => {
    savePopup.mutate(messageType);
  };

  const {isPC} = useMediaQuery();
  const router = useRouter();

  const isSpecificPage =
    router.asPath === HOME ||
    router.asPath === '/following/company' ||
    router.asPath.startsWith('/recruiting_info/view/');
  const isShow = isPC || isSpecificPage;

  return {
    data,
    user,
    isOpen,
    isLoading,
    closePopup,
    isShow,
  };
};
