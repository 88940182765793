type IAccessTokenCheckResult = {
  isValid: boolean;
  providedAccessToken: string;
};
// MEMO: アクセストークンの有無をチェック
export function checkAccessTokenExistence(
  accessToken: string | undefined,
): IAccessTokenCheckResult {
  if (typeof accessToken !== 'string')
    return {isValid: false, providedAccessToken: ''};
  return {isValid: Boolean(accessToken), providedAccessToken: accessToken};
}
