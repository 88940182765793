// See: https://www.memory-lovers.blog/entry/2021/01/18/022021
// see: https://stackoverflow.com/questions/31626231/custom-error-class-in-typescript
export class UnexpectedError extends Error {
  constructor(e?: string) {
    super(e);
    this.name = new.target.name;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
      Error.captureStackTrace(this, this.constructor);
    }

    // https://future-architect.github.io/typescript-guide/exception.html
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
