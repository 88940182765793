import {MdExitToApp, MdPerson} from 'react-icons/md';
import {LOGIN, MAIL_AUTH} from '../../../config/constants/routes';

const items = [
  {
    id: 'login',
    icon: <MdExitToApp size={20} />,
    href: LOGIN,
    label: 'ログイン',
  },
  {
    id: 'registration',
    icon: <MdPerson size={20} />,
    href: MAIL_AUTH,
    label: '無料会員登録',
  },
];

export const HeaderAuthActionMenu: React.FC = () => {
  return (
    <ul className="bg-background-1 text-primary divide-line flex flex-col divide-y py-4 px-12 w-[197px]">
      {items.map((item) => {
        const {id, icon, href, label} = item;

        return (
          <li key={id || item.href}>
            <a href={href} className="flex items-center p-8">
              <div className="mr-8">{icon}</div>
              <div>{label}</div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
